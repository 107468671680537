import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import "bootstrap/dist/css/bootstrap.min.css"

import Navbar from "react-bootstrap/Navbar"
import { Nav } from "react-bootstrap"

import "../styles/navbar.css"

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `white`,
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="white"
        variant="light"
        fixed="top"
        style={{
          margin: "0 auto",
          paddingLeft: "15px",
          paddingRight: "15px",
          maxWidth: "2160px"
        }}
        id="business-nav"
      >
        <Navbar.Brand>
          <Link to="/" className="link-no-style">
            <StaticImage
              src="../images/sprite_logo_equoo.png"
              width={150}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="A Gatsby astronaut"
              style={{ marginBottom: `0` }}
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav
            style={{
              width: "100%",
              marginBottom: "25px",
              marginLeft: "3%"
            }}
          >
            <Link
              to="/about-equoo"
              style={{
                width: "100px",
              }}
            >
              <Nav.Link as="span" eventKey="about-equoo" id="nav-items">
                About
              </Nav.Link>
            </Link>
            <Link
              to="/blog"
              style={{
                width: "100px",
              }}
            >
              <Nav.Link as="span" eventKey="clinical-research" id="nav-items">
                Blog
              </Nav.Link>
            </Link>

            <Link
              to="/contact-us"
              style={{
                width: "100px",
              }}
            >
              <Nav.Link as="span" eventKey="equoo-blog" id="nav-items">
                Contact
              </Nav.Link>
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
