/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import { Col, Container, Row } from "react-bootstrap"

import Header from "./header"
import "./layout.css"

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faInstagram, faTwitter, faFacebookF, faYoutube, faLinkedin} from '@fortawesome/free-brands-svg-icons'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div
      >
        <main>{children}</main>
        <div className="footer"
        style={{
          marginTop: `2rem`,
        }}
      >
                <Container fluid className="px-0 main">
                <Row className="justify-content-center footer-row">
                  <Col md="12" className="d-flex justify-content-center">
                  <span>
                                <a
                                    style={{
                                    paddingRight: "40px",
                                    float: "left"
                                }} className="footer-icon"
                                    href='https://www.instagram.com/equoogame/?hl=en'
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <FontAwesomeIcon
                                        icon={faInstagram}
                                        size="1x"
                                        style={{
                                        color: "#3D3D3D"
                                    }}/>
                                </a>
                                <a
                                    style={{
                                    paddingRight: "40px",
                                    float: "left"
                                }} className="footer-icon"
                                    href='https://twitter.com/eQuoogame?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthors'
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <FontAwesomeIcon
                                        icon={faTwitter}
                                        size="1x"
                                        style={{
                                        color: "#3D3D3D"
                                    }}/>
                                </a>
                                <a
                                    style={{
                                    paddingRight: "40px",
                                    float: "left"
                                }} className="footer-icon"
                                    href='https://www.facebook.com/eQuoogame/'
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <FontAwesomeIcon
                                        icon={faFacebookF}
                                        size="1x"
                                        style={{
                                        color: "#3D3D3D"
                                    }}/>
                                </a>
                                <a
                                    style={{
                                    paddingRight: "40px",
                                    float: "left"
                                }} className="footer-icon"
                                    href='https://www.youtube.com/channel/UCvpkb8Lb7e2mwP39RVC6zHQ'
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <FontAwesomeIcon
                                        icon={faYoutube}
                                        size="1x"
                                        style={{
                                        color: "#3D3D3D"
                                    }}/>
                                </a>
                                <a
                                    style={{
                                    paddingRight: "40px",
                                    float: "left"
                                }} className="footer-icon"
                                    href='https://www.linkedin.com/company/equoo/'
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <FontAwesomeIcon
                                        icon={faLinkedin}
                                        size="1x"
                                        style={{
                                        color: "#3D3D3D"
                                    }}/>
                                </a>
                            </span>
                  </Col>
                           
                        </Row>
        </Container>
        <div className="main-footer" style={{textAlign: "center", background: "#5E6366"}}>
            <span style={{alignContent: "center", textAlign: "center"}}>
                <div style={{ textAlign: "center", paddingLeft: "15px", paddingTop: "1%", paddingBottom: "1%", color: "white"}}>
                © {new Date().getFullYear()}, by PsycApps Ltd &nbsp;&nbsp;
                </div>
            </span>
        </div>
      </div>
      </div>

    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
